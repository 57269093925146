<template>
  <div>
    <h1 class="text-bold text-size-h1 mt-l mb-s">{{ $metaInfo.title }}</h1>
    <div v-if="pending"><BaseSpinner brand /></div>
    <div v-else>
      <div
        v-for="item in organizations"
        :key="item.id"
        class="panel radius-m mb-s org-card">
        <div class="row">
          <div class="col-md-8 col-sm-8 col-xs-12">
            <router-link
              :to="{ name: 'mentor-organization', params: { id: item.id } }"
              class="text-size-h3 text-bold mb-xs org-card__title"
              >{{ item.full_name || item.short_name }}</router-link
            >
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12">
            <a
              :href="item.externalLink"
              class="link org-card__external-link"
              target="_blank"
              rel="noopener noreferrer"
              >Кабинет организатора
              <BaseIcon
                glyph="link"
                width="12px"
            /></a>
          </div>
          <!-- <div class="col-md-4 col-sm-4 col-xs-12 org-card__status">
            <div v-if="item.is_permitted" class="color-success text-size-s">
              Соглашение подписано&nbsp;<BaseIcon glyph="done" />
            </div>
            <div v-else class="color-error text-size-s">
              Соглашение не подписано&nbsp;<BaseIcon glyph="warn" />
            </div>
          </div> -->
        </div>
        <div>{{ item.address }}</div>
        <div v-if="item.admin_users">
          Наставники: <span>{{ item.admin_users.length }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MentorOrganizations",
  metaInfo() {
    return {
      title: "Организации",
      titleTemplate: `%s - личный кабинет наставника Национальной технологической олимпиады`,
    };
  },
  data() {
    return {
      pending: false,
    };
  },
  computed: {
    organizations() {
      return this.$store.getters["mentor/orgList"].map((org) => {
        return {
          ...org,
          externalLink: `${process.env.VUE_APP_TALENT_BASE_URL}/org/organizations/${org.id}/statistics`,
        };
      });
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.pending = true;
      await this.$store.dispatch("mentor/getMyOrganizations", {
        noCache: true,
      });
      if (this.organizations?.length === 1) {
        this.$router.push({
          name: "mentor-organization",
          params: { id: this.organizations[0].id },
        });
      }
      this.pending = false;
    },
  },
};
</script>

<style lang="less" scoped="true">
.org-card {
  position: relative;

  &__status {
    text-align: right;
    .media-min-sm({
      text-align: left;
    });
  }

  &__title {
    display: block;
    color: inherit;
    text-decoration: none;
    transition: color 0.3s;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:hover {
      color: @link-color;
    }
  }

  &__external-link {
    display: inline-flex;
    align-items: center;
    position: relative;
    font-size: 14px;
    float: right;
    margin-bottom: 5px;

    .base-icon {
      margin-left: 10px;
    }

    .media-max-xs({
      float: left;
    });
  }
}
</style>
